//if last elem in a group is invalid, it searches for next group's first valid elem
    //should it be let go as in default order
//what happens to elements with height:0?
;(function(){
    var registered = false;

    if(lyteDomObj){

        var grpArr=[],groupNo,focArr=[];
  
    $L.focusStack = function(param){
        if( registered ){
            return;
        }

        registered = true;
        document.addEventListener('keydown',function(event){
                focArr = document.querySelectorAll('[lyte-focus-after]');
                if(event.key=='Tab' && event.shiftKey==false){
                    let currentActiveElement=document.activeElement, nextElementNfe=currentActiveElement.getAttribute('lyte-nfocus-name'),
                    nextElementTabind=currentActiveElement.getAttribute('data-tabindex');

                    if(nextElementNfe!=null && document.querySelector(`[lyte-focus-name=${nextElementNfe}]`)!=null ){
                        event.preventDefault();
                        if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback')&& currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){
                                param.onBeforeChange();
                            }
                        document.querySelector(`[lyte-focus-name=${nextElementNfe}]`).focus();
                        if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {
                            param.onAfterChange();}
                    }
                    else if(nextElementTabind!=null && nextElementTabind!=undefined && nextElementTabind.includes('group')){
                        let bool = true;
                            groupNo= (currentActiveElement.getAttribute('data-tabindex').split('group')[1]);
                            groupNo=Number.parseInt( groupNo.split('-')[0]);
                            grpArr=document.querySelectorAll(`[data-tabindex^="group${groupNo}-"]`);
                            grpArr=Array.from(grpArr);
                            if(grpArr.length == 0){
                                return;
                            }
                            grpArr.sort(sortFn);

                        curInd=grpArr.indexOf( currentActiveElement );
                                
                        if(curInd<grpArr.length-1){
                            let instInd = curInd + 1;
                            let Elem = grpArr[instInd];
                            while(Elem && !isValid(Elem)){
                                    Elem = grpArr[instInd];
                                    instInd += 1;
                            }

                            if(Elem && isValid(Elem)){
                                event.preventDefault();
                                if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){ param.onBeforeChange();}
                                bool = false;
                                Elem.focus();
                                if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback')&&currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onAfterChange();}
                            }
                        }

                        if( bool ){
                            if(param && param.ltPropChangeFocus && param.ltPropChangeFocus != 'false'){
                                let old_elem = document.activeElement;
                                changeFocus(groupNo);
                                if( old_elem != document.activeElement ){
                                    return ;
                                }
                            }
                            let nxtGrpArr = Array.from(document.querySelectorAll(`[data-tabindex^="group${groupNo+1}"]`));
                            if(nxtGrpArr.length == 0){
                                return;
                            }
                            nxtGrpArr.sort(sortFn);
                            let instInd = 0;
                            let Elem = nxtGrpArr[instInd];
                            while(Elem && !isValid(Elem)){
                                    Elem = nxtGrpArr[instInd];
                                    instInd += 1;
                            }
                            if( Elem && isValid(Elem)){
                                event.preventDefault();
                                if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){ param.onBeforeChange();}
                                Elem.focus();
                                if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback')&&currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onAfterChange();}
                            }
                            else if(checkLoopNext()){
                                event.preventDefault();
                            }
                        } 
                    }
                }
                else if(event.key=='Tab' && event.shiftKey==true){
                    let currentActiveElement=document.activeElement, prevElementNfe=currentActiveElement.getAttribute('lyte-bfocus-name'),
                    prevElementTabind=currentActiveElement.getAttribute('data-tabindex'),
                    focusAfter = currentActiveElement.getAttribute('lyte-focus-after');

                    if(prevElementNfe!=null &&  document.querySelector(`[lyte-focus-name=${prevElementNfe}]`)!=null ){
                        event.preventDefault();
                        if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback')&& currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onBeforeChange();}
                        document.querySelector(`[lyte-focus-name=${prevElementNfe}]`).focus();
                        if(param && param.onAfterChange  && currentActiveElement.getAttribute('lyte-is-callback')&&currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") { param.onAfterChange();}
                    }
                    else if(prevElementTabind!=null && prevElementTabind!=undefined && prevElementTabind.includes('group')){
                        let bool = true;
                            if(grpArr.length == 0 || parseInt((currentActiveElement.getAttribute('data-tabindex').split('group')[1]).split('-')[0])!=groupNo){
                                groupNo= (currentActiveElement.getAttribute('data-tabindex').split('group')[1]);
                                groupNo=Number.parseInt( groupNo.split('-')[0]);
                                grpArr=document.querySelectorAll(`[data-tabindex^="group${groupNo}-"]`);
                                grpArr=Array.from(grpArr);
                                if(grpArr.length == 0){
                                    return;
                                }
                                grpArr.sort(sortFn);
                            }
                            curInd=grpArr.indexOf( currentActiveElement );
                                    
                            if(curInd>0){
                                let instInd = curInd - 1;
                                let Elem = grpArr[instInd];
                                while(Elem && !isValid(Elem)){
                                        Elem = grpArr[instInd];
                                        instInd -= 1;
                                }

                                if(Elem && isValid(Elem)){
                                    event.preventDefault();
                                    if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){ param.onBeforeChange();}
                                    bool = false;
                                    Elem.focus();
                                    if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback')&&currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onAfterChange();}
                                }
                            }
                            
                            if( bool ){
                                let nxtGrpArr = Array.from(document.querySelectorAll(`[data-tabindex^="group${groupNo-1}"]`));
                                if(nxtGrpArr.length == 0){
                                    return;
                                }
                                nxtGrpArr.sort(sortFn);
                                let instInd = nxtGrpArr.length - 1;
                                let Elem = nxtGrpArr[instInd];
                                while(Elem && !isValid(Elem)){
                                        Elem = nxtGrpArr[instInd];
                                        instInd -= 1;
                                }

                                if( Elem && isValid(Elem)){
                                    event.preventDefault();
                                    if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){ param.onBeforeChange();}
                                    Elem.focus();
                                    if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback')&&currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onAfterChange();}
                                }
                                else if(checkLoopPrev()){
                                    event.preventDefault();
                                }
                            }
                    }
                    else if( focusAfter ){
                        let contArr=focusAfter.split(',');
                        let grpNo = Number.parseInt(contArr[0]);
                        let nxtGrpArr = Array.from(document.querySelectorAll(`[data-tabindex^="group${grpNo}"]`));
                        if(nxtGrpArr.length == 0){
                            return;
                        }
                        nxtGrpArr.sort(sortFn);
                        let instInd = nxtGrpArr.length - 1;
                        let Elem = nxtGrpArr[instInd];
                        while(Elem && !isValid(Elem)){
                                Elem = nxtGrpArr[instInd];
                                instInd -= 1;
                        }

                        if( Elem && isValid(Elem)){
                            event.preventDefault();
                            if(param && param.onBeforeChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true"){ param.onBeforeChange();}
                            Elem.focus();
                            if(param && param.onAfterChange && currentActiveElement.getAttribute('lyte-is-callback') && currentActiveElement.getAttribute('lyte-is-callback').toLowerCase()=="true") {param.onAfterChange();}
                        }
                    }
                }
        });
    }

    function checkLoopNext(){
        let currentActiveElement=document.activeElement;

        groupNo= (currentActiveElement.getAttribute('data-tabindex').split('group')[1]);
        groupNo=Number.parseInt( groupNo.split('-')[0]);
        grpArr=document.querySelectorAll(`[data-tabindex^="group${groupNo}-"]`);
        grpArr=Array.from(grpArr);
        curInd=Number.parseInt(currentActiveElement.getAttribute('data-tabindex').split('-')[1]);
        let nextDomElement=grpArr[grpArr.indexOf(currentActiveElement)+1];
        if(nextDomElement==null) { 
            return false;
        }
        if( groupNo==Number.parseInt((nextDomElement.getAttribute('data-tabindex').split('group')[1]).split('-')[0])&& Number.parseInt(nextDomElement.getAttribute('data-tabindex').split('-')[1])<curInd){
            return true;
        }
        return false;

    }

    function checkLoopPrev(){
        let currentActiveElement=document.activeElement;

        groupNo= (currentActiveElement.getAttribute('data-tabindex').split('group')[1]);
        groupNo=Number.parseInt( groupNo.split('-')[0]);
        grpArr=document.querySelectorAll(`[data-tabindex^="group${groupNo}-"]`);
        grpArr=Array.from(grpArr);
        curInd=Number.parseInt(currentActiveElement.getAttribute('data-tabindex').split('-')[1]);
        let prevDomElement=grpArr[grpArr.indexOf(currentActiveElement)-1];
        if(prevDomElement==null) {return false;}
        if( groupNo==Number.parseInt((prevDomElement.getAttribute('data-tabindex').split('group')[1]).split('-')[0])&& Number.parseInt(prevDomElement.getAttribute('data-tabindex').split('-')[1])>curInd){
            return true;
        }
        return false;
    }

    function changeFocus(groupNo){
        focArr.forEach(function(ele){
            if(ele.getAttribute('lyte-focus-after')){
                    let contArr=ele.getAttribute('lyte-focus-after').split(',');
                    if(contArr.includes(groupNo.toString()) && isValid(ele)){
                        event.preventDefault();
                        ele.focus();
                    }
            }
        });
    }
    
    function sortFn(a,b){
        curIndA=getInd(a);
        curIndB=getInd(b);

        if(curIndA != -1 && curIndB != -1){
            if(curIndA<curIndB) {
                return -1;
            }
            if(curIndA>curIndB){ 
                return 1;
            }
        }
        return 0;
    }

    function getInd( elem ){
        let curInd = -1,e;      
        
        if( elem ){
            e =  elem.getAttribute('data-tabindex') ;
            if( e ){
                curInd = Number.parseInt( e.split('-')[1] ) ;
            }
        }
        
        return isNaN( curInd ) ? -1 : curInd ;
    }

    function isValid( Elem ){
        return $L(Elem).is(":visible")
             && !Elem.disabled 
                && Elem.tabIndex > -1;

    }

  }

  } )();  
