Lyte.Component.register("lyte-tree-table", {
_template:"<template tag-name=\"lyte-tree-table\" class=\"lyteTreeTable\" style=\"display:table;\"> <template is=\"if\" value=\"{{expHandlers(calledFromInside,'!')}}\"><template case=\"true\"> <lyte-tree-table-header> <lyte-yield yield-name=\"treeTableHead\" header-value=\"{{ltPropHeader}}\"></lyte-yield> </lyte-tree-table-header> </template></template> <lyte-tree-table-body class=\"lyteTreeTableBody\" style=\"display:table-row-group;\"> <template is=\"for\" items=\"{{treeTableLinearArr}}\" item=\"item\" index=\"index\"> <lyte-yield class=\"lyteTreeTableRow lyteTreeTableOpenedRow\" yield-name=\"treeTableRow\" list-value=\"{{item}}\" style=\"--tree-level:{{treeTableLevelMaps[index].level}};\" data-level=\"{{expHandlers(treeTableLevelMaps[index].level,'+','')}}\" data-index=\"{{expHandlers(treeTableLevelMaps[index].dataIndex,'+','')}}\" dom-index=\"{{index}}\"> </lyte-yield> </template> </lyte-tree-table-body> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1,1]},{"type":"insertYield","position":[1,1]},{"type":"componentDynamic","position":[1]}]}},"default":{}},{"type":"attr","position":[3,1]},{"type":"for","position":[3,1],"dynamicNodes":[{"type":"attr","position":[1],"attr":{"style":{"name":"style","helperInfo":{"name":"concat","args":["'--tree-level:'","treeTableLevelMaps[index].level","';'"]}}}},{"type":"insertYield","position":[1]}]},{"type":"componentDynamic","position":[3]}],
_observedAttributes :["ltPropHeader","ltPropData","ltPropAsTree","ltPropScrollSpeed","ltPropRowSortable","ltPropColumnSortable","leftInterSectionArr","rootMarginArr","treeTableLinearArr","treeTableLinearPopulateArr","treeTableLevelMaps","initialClientY","initialTop","initialLeft","exeDrop","scrollTriggered","stopScroll","treeTableDimension","treeTableScrollHeight","currentSortIndex","currentSortHeader"],

	data : function(){
		return {
			ltPropHeader : Lyte.attr('array' , {
				default : []
			}),
			ltPropData: Lyte.attr('array', {
				default: []
			}),
			ltPropAsTree : Lyte.attr('string' , {
				default : '1'
			}),
			ltPropScrollSpeed : Lyte.attr('number' , {
				default : 5
			}),
			ltPropRowSortable : Lyte.attr('boolean' , {
				default : true
			}),
			ltPropColumnSortable : Lyte.attr('boolean' , {
				default : true
			}),

			leftInterSectionArr : Lyte.attr('array',{
				default:[0]
			}),
			rootMarginArr : Lyte.attr('array' ,{
				default : []
			}),


			treeTableLinearArr : Lyte.attr('array' , {default : []}),
			treeTableLinearPopulateArr : Lyte.attr('object' , {default : {}}),
			treeTableLevelMaps : Lyte.attr('array' , {default : []}),
			initialClientY : Lyte.attr('number' , {default : 0}),
			initialTop : Lyte.attr('number' , {default : 0}),
			initialLeft : Lyte.attr('number' , {default : 0}),
			exeDrop : Lyte.attr('boolean' , {default : false}),
			scrollTriggered : Lyte.attr('boolean' , {default : false}),
			stopScroll : Lyte.attr('boolean' , {default : true}),

			
			treeTableDimension : Lyte.attr('object'),
			treeTableScrollHeight : Lyte.attr('number'),
			currentSortIndex : Lyte.attr('number'),
			currentSortHeader : Lyte.attr('number')
		}		
	},

	init : function(){
		var tree = this.getData('ltPropData')
		var _this = this
		var level = 0;
		var dataIndex = "";

		function getArray(tree){
			var treeMaps = {}
			level+=1
			treeMaps.level = level
			for(var i=0;i<tree.length;i++){
				treeMaps.parentPath = dataIndex
				if(dataIndex.length < 1){
					dataIndex = dataIndex + i
				} else {
					dataIndex = dataIndex + " " + i 
				}
				treeMaps.dataIndex = dataIndex
				treeMaps.arrayIndex = i
				var newTreeMap = Object.assign({},treeMaps)
				if(tree[i].stateCollapsed){
					newTreeMap.stateCollapsed = true
				} else {
					newTreeMap.stateCollapsed = false
				}
				Lyte.arrayUtils(_this.getData('treeTableLevelMaps') , 'push' , newTreeMap)
				Lyte.arrayUtils(_this.getData('treeTableLinearArr') , 'push' , tree[i])
				if(tree[i].children && tree[i].children.length >= 0){
					getArray(tree[i].children)
				}
				dataIndex = dataIndex.substring(0 , dataIndex.length-2)
			}
			level-=1
			return
		}
		getArray(tree)
	},
	didConnect : function(){
		$L(this.$node)[0].addEventListener('mousedown' , this.mouseDownFunction)
		var _this = this
		var toFixLeft = 0;
		var parentElem = this.$node.parentElement;

		var treeTableHeaader = $L(this.$node).find('lyte-tree-table-th')
        var rootMarginRight = 0;
        $L(this.$node).find('lyte-tree-table-th.lyteTreeFixedHeader').addClass('addObserve')

        if(treeTableHeaader[0].hasAttribute('fixed')){
            for(var i=1;i<treeTableHeaader.length;i++){
                if(!treeTableHeaader[i].hasAttribute('fixed')){
                    $L(treeTableHeaader[i-1]).removeClass('addObserve')
                    rootMarginRight += treeTableHeaader[i-1].getBoundingClientRect().width
                    $L(treeTableHeaader[i]).addClass('addObserve')
                    break;
                }
            }
        }
		for(var i=0;i<treeTableHeaader.length;i++){
			if($L(treeTableHeaader[i]).hasClass('lyteTreeFixedHeader')){
				$L(('.lyteTreeTableCol'+i)).addClass('lyteTreeFixedCol')
				if(i === 0){
					fixLeft(treeTableHeaader[i] , 1)
					fixLeft(('.lyteTreeTableCol'+i) , 1)
					toFixLeft += treeTableHeaader[i].getBoundingClientRect().width
				}else {
					Lyte.arrayUtils(this.getData('leftInterSectionArr') , 'push' , toFixLeft);
					fixLeft(treeTableHeaader[i] , toFixLeft+1)
					fixLeft(('.lyteTreeTableCol'+i) , toFixLeft+1)
					toFixLeft += treeTableHeaader[i].getBoundingClientRect().width
				}
			}
		}

		function fixLeft(node , val){
			$L(node).css({"left" : val})
		}

        for(var i=0;i<treeTableHeaader.length;i++){
            var rootMarginStr = "0px 0px 0px -"
            if($L(treeTableHeaader[i]).hasClass('addObserve')){
                rootMarginRight += treeTableHeaader[i].getBoundingClientRect().width;
                Lyte.arrayUtils(this.getData('rootMarginArr') , 'push' , (rootMarginStr + rootMarginRight +"px"));
                if(!$L(treeTableHeaader[i]).hasClass('lyteTreeFixedHeader')){
                    rootMarginRight -= treeTableHeaader[i].getBoundingClientRect().width;
                }
            }
        }

		var fixedCols = $L(this.$node).find('.addObserve')
		var rootMargin = this.getData('rootMarginArr')

		// for(var i=0;i<fixedCols.length;i++){
		// 	$L(fixedCols[i]).data('intersectionIndex' , i)

        //     const observer = new IntersectionObserver(function(entries){
        //         var headInd;
        //         var entryInd;
        //         var entryColInd;

        //         var entry = entries[0];

        //         entryInd = fixedCols.indexOf(entry.target)
        //         console.log(entry.target.innerText , entry.isIntersecting);
        //         if(entry.isIntersecting){
        //             if($L(entry.target).data().intersectionIndex>0 && $L(_this.$node).find('.lyteColumnSticked')[$L(entry.target).data().intersectionIndex-1]){
        //                 _this.addFixedClass($L(_this.$node).find('.lyteColumnSticked')[$L(entry.target).data().intersectionIndex-1]);
        //             }
        //             headInd = $L(entry.target).attr('head-index');
        //             $L(entry.target).removeClass('lyteTreeLastFixedCol')
        //             $L(_this.$node).find(('.lyteTreeTableCol' + headInd)).removeClass('lyteTreeLastFixedCol')
        //         } else {
        //             $L(_this.$node).find('.lyteTreeLastFixedCol').removeClass('lyteTreeLastFixedCol')
        //             headInd = $L(entry.target).attr('head-index');
        //             _this.addFixedClass(entry.target)
        //             if(entryInd-1>=0 && $L(fixedCols[entryInd-1]).hasClass('lyteTreeLastFixedCol')){
        //                 entryColInd = $L($L(fixedCols[entryInd-1])).attr('head-index')
        //                 $L(_this.$node).find(('.lyteTreeTableCol' + entryColInd)).removeClass('lyteTreeLastFixedCol')
        //                 $L(fixedCols[entryInd-1]).removeClass('lyteTreeLastFixedCol')
        //             }
        //         }
    
        //     },{
        //         root: parentElem,
        //         rootMargin : rootMargin[i]
        //     })

        //     observer.observe(fixedCols[i])

		// }

	},

	addFixedClass : function(node){
		var intersectionIndex = $L(node).data().intersectionIndex;
		var headInd = parseInt($L(node).attr('head-index'))
		var currCol = $L(this.$node).find(('.lyteTreeTableCol'+headInd))
		$L(node).addClass('lyteTreeLastFixedCol lyteColumnSticked')
		$L(currCol).addClass('lyteTreeLastFixedCol lyteColumnSticked')
		var nextNode = this.getNextStickedHead(intersectionIndex)
		if(nextNode){
			var nextCol = this.getColumn(nextNode)
			nextCol.removeClass('lyteColumnSticked')
			$L(nextNode).removeClass('lyteColumnSticked')
		}
	},

	removeFixedClass : function(node){
		var headInd = parseInt($L(node).attr('head-index'))
		var currCol = $L(this.$node).find(('.lyteTreeTableCol'+headInd))
		$L(node).addClass('lyteTreeLastFixedCol')
		$L(currCol).addClass('lyteTreeLastFixedCol')
	},

	getColumn:function(node){
		var headInd = parseInt($L(node).attr('head-index'))
		return $L(this.$node).find(('.lyteTreeTableCol'+headInd));
	},

	getNextStickedHead:function(num){
		return $L(this.$node).find(".lyteTreeFixedHeader.lyteColumnSticked")[num+1]
	},

	getPrevHeadInd:function(node){
		var currInd = parseInt($L(node).attr('head-index'))
		if(currInd > 0){
			return currInd - 1
		}
		return 0
	},
	getCurrentHeadInd:function(node){
		return parseInt($L(node).attr('head-index'))
	},

	methods : {
		onBeforeOpen : function(){},
		onOpen : function(){},
		onBeforeClose : function(){},
		onClose : function(){},
		onDragStart : function(){},
		onDrag : function(){},
		onDragEnd : function(){},
		onBeforeDrop : function(){},
		onDrop : function(){}
	},
	actions : {
		toggleTree : function(th){
			var currentDomIndex = parseInt($L(th).closest('tr').attr('dom-index'))
			if($L(th).hasClass('lyteTreeTableClosed')){
				$L(th).removeClass('lyteTreeTableClosed')
				$L(th).addClass('lyteTreeTableOpened')
				$L(th).closest('.lyteTreeTableRow').addClass('lyteTreeTableOpenedRow')
				$L(th).closest('.lyteTreeTableRow').removeClass('lyteTreeTableClosedRow')
				Lyte.objectUtils(this.getData('treeTableLevelMaps')[currentDomIndex] ,'add', 'stateCollapsed' , false);
				this.renderTree(this.getData('treeTableLevelMaps')[currentDomIndex] , currentDomIndex)
			} else if($L(th).hasClass('lyteTreeTableOpened')){
				var endIndex = this.findSibling($L(th).closest('tr').attr('data-index'))
				var startIndex = parseInt($L(th).closest('tr').attr('dom-index')) + 1
				Lyte.objectUtils(this.getData('treeTableLevelMaps')[currentDomIndex] ,'add', 'stateCollapsed' , true);
				Lyte.arrayUtils(this.getData('treeTableLinearArr') , 'removeAt' , startIndex , (endIndex - startIndex))
				Lyte.arrayUtils(this.getData('treeTableLevelMaps') , 'removeAt' , startIndex , (endIndex - startIndex))	
				$L(th).closest('.lyteTreeTableRow').removeClass('lyteTreeTableOpenedRow')
				$L(th).closest('.lyteTreeTableRow').addClass('lyteTreeTableClosedRow')
				$L(th).removeClass('lyteTreeTableOpened')
				$L(th).addClass('lyteTreeTableClosed')
			}
		}
	},

	getNextElem : function(dataIndex){

		var dataIndexArr = dataIndex.split(" ");
		var _this = this;
		var nextInd = ''

		function getNextNum(dataIndexArr){

			var currentInd = parseInt(dataIndexArr[dataIndexArr.length-1])
			dataIndexArr.pop()
			dataIndexArr.push((currentInd+1))
			var newDataInd = dataIndexArr.join(' ')
			if($L(_this.$node).find(".lyteTreeTableRow[data-index='"+newDataInd+"']")[0]){
				nextInd = newDataInd;
				return newDataInd
			} else {
				nextInd = ''
				if(dataIndexArr.length > 1){
					dataIndexArr.pop()
					getNextNum(dataIndexArr)
				}

			}


		}

		getNextNum(dataIndexArr)
		return nextInd;

	},

	getCurrentIndex : function(dataIndex){
		var arr = dataIndex.split(" ")
		return arr[arr.length - 1]
	},
	changeCurrentIndex : function(dataIndex , ind){
		var arr = dataIndex.split(" ")
		arr[arr.length - 1] = ind
		return arr.join(" ")
	},

	findSibling : function(dataIndex){

		var nextIndex = parseInt(this.getCurrentIndex(dataIndex)) + 1
		var endDataIndex = this.changeCurrentIndex(dataIndex , nextIndex)
		var endIndexElem = $L(this.$node).find('tr[data-index="'+ endDataIndex +'"]')
		var childDataIndex = dataIndex + " 0"
		var childElem = $L(this.$node).find('tr[data-index="'+ childDataIndex +'"]')
		var _this = this
		var lastIndex;

		function childrenLength(endDataIndex){
			var edi = endDataIndex.split(" ")
			var ni = ""
			if(endDataIndex.length < 2){
				ni = (parseInt(endDataIndex)+1).toString()
			} else {
				for(var i=0;i<(edi.length-1);i++){
					if((i === 0) && (i < (edi.length-2))){
						ni += edi[i]
					} else if(i === (edi.length-2)){
						if(ni.length>=1){
							ni = ni + " " + (parseInt(edi[i])+1)
						} else {
							ni = (parseInt(edi[i])+1).toString()
						}
					} else {
						ni = ni + " " + edi[i]
					}
				}
			}
			var eie = $L(_this.$node).find('tr[data-index="'+ ni +'"]')
			if(!eie[0] && ni.length > 1){
				childrenLength(ni)
			} else if(!eie[0] && ni.length === 1){
				var totalTr = $L(_this.$node).find('tr')
				ni = $L(totalTr[totalTr.length-1]).attr('data-index');
				lastIndex = ni
			}
			if(eie[0]){
				lastIndex = ni
			}
			return 
		}
		if(!endIndexElem[0]){
			childrenLength(endDataIndex)
			endIndexElem = $L(this.$node).find('tr[data-index="'+ lastIndex +'"]')
			if(parseInt(endIndexElem.attr('dom-index')) === this.getData('treeTableLinearArr').length-1){
				return parseInt(endIndexElem.attr('dom-index'))+1
			}
		} else {
			endIndexElem = $L(this.$node).find('tr[data-index="'+ endDataIndex +'"]')
		}
		return parseInt(endIndexElem.attr('dom-index'))
	},
	getTreeData : function(dataIndex){
		var indArr = dataIndex.split(" ")
		var treeData = this.getData('ltPropData')

		for(var i=0;i<indArr.length-1;i++){
			treeData = treeData[parseInt(indArr[i])].children
		}
		
		return treeData

	},
	getTreeObj : function(dataIndex){
		var indArr = dataIndex.split(" ")
		var treeData = this.getData('ltPropData')

		for(var i=0;i<indArr.length-1;i++){
			treeData = treeData[parseInt(indArr[i])].children
		}
		
		return treeData[indArr[indArr.length-1]]

	},
	generateFlatArray : function(obj){
		var treeDatas = []
		treeDatas.push(obj);
		function getArray(tree){
			for(var i=0;i<tree.length;i++){
				treeDatas.push(tree[i])
				if(tree[i].children && tree[i].children.length >= 0){
					getArray(tree[i].children)
				}
			}
			return
		}
		if(obj.children){
			getArray(obj.children)
		}
		return treeDatas
	},
	generateMapArray : function(obj , dropObjInd , dropObj , parentLevel){
		var treeDatas = []
		var len
		if(!dropObj.children){
			len = 0
		} else {
			len = dropObj.children.length
		}
		var level = parentLevel

		if(!len){
			len = 0
		}
		if(!parentLevel){
			level = 0
		}

		var di = dropObjInd;

		function getArray(tree){
			var treeMaps = {}
			level+=1
			treeMaps.level = level
			for(var i=0;i<tree.length;i++){
				treeMaps.parentPath = di
				if(di.length < 1){
					di = di + (i+len)
				} else {
					di = di + " " + (i+len) 
				}
				treeMaps.dataIndex = di
				treeMaps.arrayIndex = i+len
				var newTree = Object.assign({},treeMaps)
				if(tree[i].stateCollapsed){
					newTree.stateCollapsed = true
				} else {
					newTree.stateCollapsed = false
				}
				treeDatas.push(newTree)
				if(tree[i].children && tree[i].children.length >= 0){
					len = 0
					getArray(tree[i].children)
				}
				di = di.substring(0 , di.length-2)
			}
			level-=1
			return
		}
		getArray([obj])
		return treeDatas;
	},
	generateMapArrayForSib : function(obj , dropObjInd , dropObj , parentLevel , bottomInd){
		
		var treeDatas = []
		var len = 0
		if(!dropObj.children){
			len = 0
		} else {
			len = dropObj.children.length
		}
		var level = parentLevel

		if(!parentLevel){
			level = 0
		}
		if(!bottomInd){
			bottomInd = 0
		}

		var di = dropObjInd;

		function getArray(tree){
			var treeMaps = {}
			level+=1
			treeMaps.level = level
			for(var i=0;i<tree.length;i++){
				treeMaps.parentPath = di
				if(di.length < 1){
					di = di + (i + bottomInd)
				} else {
					di = di + " " + (i + bottomInd) 
				}
				treeMaps.dataIndex = di
				treeMaps.arrayIndex = i + bottomInd
				var newTree = Object.assign({},treeMaps)
				if(tree[i].stateCollapsed){
					newTree.stateCollapsed = true
				} else {
					newTree.stateCollapsed = false
				}
				treeDatas.push(newTree)
				if(tree[i].children && tree[i].children.length >= 0){
					len = 0
					getArray(tree[i].children)
				}
				di = di.substring(0 , di.length-2)
			}
			level-=1
			return
		}
		getArray([obj])
		return treeDatas;
	},
	updateMapArray : function(parentObj,parentIndex,parentLevel , cdrState){

		var treeDatas = []
		var level = parentLevel

		if(!parentLevel){
			level = 0
		}
		if(cdrState === undefined){
			cdrState = false
		}

		var di = parentIndex;
		var parentDomInd = parseInt($L(this.$node).find('.lyteTreeTableRow[data-index="'+parentIndex+'"]').attr('dom-index'))

		function getArray(tree){
			var treeMaps = {}
			level+=1
			treeMaps.level = level
			for(var i=0;i<tree.length;i++){
				treeMaps.parentPath = di
				if(di.length < 1){
					di = di + i
				} else {
					di = di + " " + i 
				}
				treeMaps.dataIndex = di
				treeMaps.arrayIndex = i
				var newTree = Object.assign({},treeMaps)
				if(tree[i].stateCollapsed){
					newTree.stateCollapsed = true
				} else {
					newTree.stateCollapsed = false
				}
				treeDatas.push(newTree)
				if(tree[i].children && tree[i].children.length >= 0){
					len = 0
					getArray(tree[i].children)
				}
				di = di.substring(0 , di.length-2)
			}
			level-=1
			return
		}
		getArray(parentObj.children)

		if(!cdrState){
			this.removeDataFromMapArray(parentDomInd+1 , treeDatas.length)
			this.insertDatasToMapArray(treeDatas , parentDomInd+1)
		} else {
			this.removeDataFromMapArray(parentDomInd+1 , 1)
			this.insertDatasToMapArray([treeDatas[0]] , parentDomInd+1)
		}

		return treeDatas;
	},
	isSame : function(dragIndex , dropIndex){
		var dragArr = dragIndex.split(" ")
		var dropArr = dropIndex.split(" ")
		var retVal = true
		for(var i=0;i<dragArr.length;i++){
			if(dragArr[i] !== dropArr[i]){
				retVal = false
				break;
			}
		}
		return retVal;
	},
	removeDataFromOrigin : function(parentPath , currentIndex){
		var path = parentPath.split(" ")
		var data = this.getData('ltPropData')
		for(var i=0 ; i<path.length ; i++){
			data = data[path[i]].children
		}
		return Lyte.arrayUtils(data , 'removeAt' , currentIndex , 1)
	},
	removeDataFromLinearArray : function(fromIndex , count){
		Lyte.arrayUtils(this.getData('treeTableLinearArr') , 'removeAt' , fromIndex , count)
		Lyte.arrayUtils(this.getData('treeTableLevelMaps') , 'removeAt' , fromIndex , count)
	},
	removeDataFromMapArray : function(fromIndex , count){
		if(count > 0){
			Lyte.arrayUtils(this.getData('treeTableLevelMaps') , 'removeAt' , fromIndex , count)
		}
	},
	addDataToOriginAsChild : function(curObj , obj){
		if(!curObj.children){
			Lyte.objectUtils(curObj , 'add' , 'children' , [])
		}
		Lyte.arrayUtils(curObj.children , 'push' , obj)
	},
	addDataToOriginAsSib : function(curObj , obj , ind){
		if(!curObj.children){
			Lyte.objectUtils(curObj , 'add' , 'children' , [])
		}
		Lyte.arrayUtils(curObj.children , 'insertAt' , ind , obj)
	},
	insertDatasToLinearArray : function(arr , ind){
		Lyte.arrayUtils(this.getData('treeTableLinearArr') , 'insertAt' , ind , arr)
	},
	insertDatasToMapArray : function(arr , ind){
		Lyte.arrayUtils(this.getData('treeTableLevelMaps') , 'insertAt' , ind , arr)
	},

	renderTree : function(levelMap , index){

		var dataIndex = levelMap.dataIndex.split(" ")
		var treeData;
		for(var i=0;i<dataIndex.length;i++){
			if(i === 0){
				treeData = this.getData('ltPropData')[dataIndex[i]]
			} else {
				treeData = treeData.children[dataIndex[i]]
			}
		}

		var treeDatas = []
		var mapDatas = []


		var _this = this
		var level = parseInt(levelMap.level);
		var di = levelMap.dataIndex;

		function getArray(tree){
			var treeMaps = {}
			level+=1
			treeMaps.level = level
			for(var i=0;i<tree.length;i++){
				if(di.length < 1){
					di = di + i
				} else {
					di = di + " " + i 
				}
				treeMaps.dataIndex = di
				var newTree = Object.assign({},treeMaps)
				if(tree[i].stateCollapsed){
					newTree.stateCollapsed = true
				} else {
					newTree.stateCollapsed = false
				}
				treeDatas.push(newTree)
				mapDatas.push(tree[i])
				// Lyte.arrayUtils(_this.getData('treeTableLevelMaps') , 'insertAt' , index+i+1 , newTree)
				// Lyte.arrayUtils(_this.getData('treeTableLinearArr') , 'insertAt' , index+i+1 , tree[i])
				if(tree[i].children && tree[i].children.length >= 0){
					getArray(tree[i].children)
				}
				di = di.substring(0 , di.length-2)
			}
			level-=1
			return
		}


		getArray(treeData.children)

		Lyte.arrayUtils(_this.getData('treeTableLevelMaps') , 'insertAt' , index+1 , treeDatas)
		Lyte.arrayUtils(_this.getData('treeTableLinearArr') , 'insertAt' , index+1 , mapDatas)


	},

	mouseDownFunction : function(eve){

		var currentTr = $L(eve.target).closest('tr')
		this.setData('treeTableDimension' , $L(this)[0].getBoundingClientRect())
		this.setData('treeTableScrollHeight' , $L(this)[0].scrollHeight)
		if(currentTr.hasClass('lyteTreeTableRow') && this.getData('ltPropRowSortable')){
			currentTr.addClass('lyteTableRowActive')
			this.setData('currentSortIndex' , currentTr.attr('dom-index'))
			this.setData('initialClientY' , eve.clientY)
			this.setData('initialTop' , currentTr[0].getBoundingClientRect().top)
			this.setData('initialLeft' , currentTr[0].getBoundingClientRect().left)

			document.addEventListener('mousemove' , this.component.mouseMoveFunction)
			document.addEventListener('mouseup' , this.component.mouseLeaveFunction)
		} else if(currentTr.hasClass('lyteTreeTableHeader') && this.getData('ltPropColumnSortable')){
			$L(eve.target).addClass('lyteTableColumnActive')
			this.setData('currentSortHeader' , $L(eve.target).attr('header-index'))
			document.addEventListener('mousemove' , this.component.colMouseMoveFunction)
			document.addEventListener('mouseup' , this.component.colMouseLeaveFunction)
		}

	},
	colMouseMoveFunction : function(eve){
		// if($L(eve.target)[0].tagName === 'TH'){
			var currentSortColumn = $L('.lyteTreeClonedColumn')[0]
			var treeTableComponent = $L('.lyteTableColumnActive').closest('lyte-tree-table')[0].component
			if(!currentSortColumn){
				currentSortColumn = $L(treeTableComponent.$node).find('.lyteTableColumnActive')[0].cloneNode(true)
				$L(currentSortColumn).addClass('lyteTreeClonedColumn')
				currentSortColumn.style.position = "absolute";
				// this.component.$node.appendChild(currentSortColumn)
				document.body.appendChild(currentSortColumn)
			}
			currentSortColumn.style.top = 10 + eve.clientY + "px"
			currentSortColumn.style.left =10 + eve.clientX + "px"
		// }
	},
	colMouseLeaveFunction : function(eve){
		var treeTableComponent = $L('.lyteTableColumnActive').closest('lyte-tree-table')[0].component
		$L('.lyteTreeClonedColumn')[0].remove()

		if($L(eve.target)[0].tagName === 'TH'){
			var insertInd = $L(eve.target).eq(0).attr('header-index')
			var removeInd = treeTableComponent.getData('currentSortHeader')
			var remData = Lyte.arrayUtils(treeTableComponent.getData('ltPropHeader') , 'removeAt' , removeInd , 1)
			Lyte.arrayUtils(treeTableComponent.getData('ltPropHeader') , 'insertAt' , insertInd , remData)
		}

		this.removeEventListener('mousemove' , treeTableComponent.colMouseMoveFunction)
        this.removeEventListener('mouseup' , treeTableComponent.colMouseLeaveFunction)
	},
	mouseMoveFunction : function(eve){

		var treeTableComponent = $L('.lyteTableRowActive').closest('lyte-tree-table')[0].component
		var treeTable = treeTableComponent.$node

		if(treeTableComponent){

			if(Math.abs(treeTableComponent.data.initialClientY - eve.clientY) > 5){

				$L(treeTable).find('.lyteTreeTableDropzone').removeClass('lyteTreeTableDropzone')
				$L(treeTable).find('.lyteTreeTableTopDropzone').removeClass('lyteTreeTableTopDropzone')
				$L(treeTable).find('.lyteTreeTableBottomDropzone').removeClass('lyteTreeTableBottomDropzone')
				$L(treeTable).find('.lyteTTDropNotAllowed').removeClass('lyteTTDropNotAllowed')
				$L(treeTable).find('.lyteTTDropAllowed').removeClass('lyteTTDropAllowed')
	
				// if(!$L(treeTable).find('.lyteTreeClonedRow')[0]){
				if(!$L('.lyteTreeClonedRow')[0]){
					var dummyTreeContent = $L(treeTable).find('.lyteTableRowActive')[0].cloneNode(true);
					$L(dummyTreeContent).addClass('lyteTreeClonedRow')
					dummyTreeContent.style.position = "absolute";
					document.body.appendChild(dummyTreeContent)
					// treeTable.appendChild(dummyTreeContent)
				}

				if($L(eve.target).closest('tr')[0]){
					var treeContentDim = $L(eve.target).closest('tr')[0].getBoundingClientRect()
					var topHeight = treeContentDim.height * .2
					var midheight = treeContentDim.height * .8

					if(eve.clientY < (treeContentDim.top + topHeight)){
				
						// topPlaceHolder
						$L(eve.target).closest('tr').addClass('lyteTreeTableTopDropzone')
			
					} else if((eve.clientY > (treeContentDim.top + topHeight)) && (eve.clientY < (treeContentDim.top + midheight))){
					
						// bodyPlaceHolder
						$L(eve.target).closest('tr').addClass('lyteTreeTableDropzone')
			
					} else if((eve.clientY > (treeContentDim.top + midheight)) && (eve.clientY < (treeContentDim.top + treeContentDim.height))){
					
						// bottomPlaceHolder
						$L(eve.target).closest('tr').addClass('lyteTreeTableBottomDropzone')
			
					}
				}
				
				var currentDraggedElem = $L(treeTable).find('.lyteTableRowActive')
				if($L(eve.target).closest('.lyteTreeTableRow')[0]){
					if(treeTableComponent.isSame(currentDraggedElem.attr('data-index') , $L(eve.target).closest('tr').attr('data-index'))){
						$L(eve.target).closest('tr').addClass('lyteTTDropNotAllowed')
						$L(treeTable).find('.lyteTreeTableDropzone').removeClass('lyteTreeTableDropzone')
						$L(treeTable).find('.lyteTreeTableTopDropzone').removeClass('lyteTreeTableTopDropzone')
						$L(treeTable).find('.lyteTreeTableBottomDropzone').removeClass('lyteTreeTableBottomDropzone')
					} else {
						$L(eve.target).closest('tr').addClass('lyteTTDropAllowed')
					}
				}  else {
					// Else part is executed when the mouse goes out of the table

					$L(treeTable).find('.lyteTreeTableDropzone').removeClass('lyteTreeTableDropzone')
					$L(treeTable).find('.lyteTreeTableTopDropzone').removeClass('lyteTreeTableTopDropzone')
					$L(treeTable).find('.lyteTreeTableBottomDropzone').removeClass('lyteTreeTableBottomDropzone')
					$L(treeTable).find('.lyteTTDropNotAllowed').removeClass('lyteTTDropNotAllowed')
					$L(treeTable).find('.lyteTTDropAllowed').removeClass('lyteTTDropAllowed')
				}
				// var currentSortElem = $L(treeTable).find('.lyteTreeClonedRow')[0]
				var currentSortElem = $L('.lyteTreeClonedRow')[0]
				currentSortElem.style.background = 'white';
				currentSortElem.style.boxShadow = "0px 0px 5px 5px #eee"
				currentSortElem.style.top = 10+eve.clientY + "px";
				currentSortElem.style.left = 10+eve.clientX + "px";
				// currentSortElem.style.top = 10 + $L(treeTable)[0].scrollTop +  eve.clientY - $L(treeTable)[0].getData().treeTableDimension.top+ "px"
				// currentSortElem.style.left = 10 + $L(treeTable)[0].scrollLeft + eve.clientX - $L(treeTable)[0].getData().treeTableDimension.left+ "px"
	
				var tableDim = treeTable.getBoundingClientRect()
				var scrollTable = function(){

					if(!treeTableComponent.getData('scrollTriggered')){
						window.cancelAnimationFrame(treeTableComponent.animation)
						treeTableComponent.setData('stopScroll' , false)
						return
					}
			
					if(eve.clientY >= (tableDim.top + tableDim.height)){
						if(treeTable.scrollTop >= (treeTableComponent.data.treeTableScrollHeight-tableDim.height)){
							window.cancelAnimationFrame(treeTableComponent.animation)
							treeTableComponent.setData('stopScroll' , false)
							return
						}
						treeTable.scrollTop += treeTableComponent.getData('ltPropScrollSpeed');
					} else if (eve.clientY <= (tableDim.top)){
						if(treeTable.scrollTop <= 0){
							window.cancelAnimationFrame(treeTableComponent.animation)
							treeTableComponent.setData('stopScroll' , false)
							return
						} 
						treeTable.scrollTop -= treeTableComponent.getData('ltPropScrollSpeed');
					}
					treeTableComponent.animation = window.requestAnimationFrame(scrollTable.bind(eve))
	
				}
				if(eve.clientY >= (tableDim.top + tableDim.height) && (treeTableComponent.getData('stopScroll')) && !treeTableComponent.getData('scrollTriggered')){
					treeTableComponent.setData('scrollTriggered' , true)
					treeTableComponent.animation = window.requestAnimationFrame(scrollTable.bind(eve))
				} else if (eve.clientY <= (tableDim.top) && (treeTableComponent.getData('stopScroll')) && !treeTableComponent.getData('scrollTriggered')){
					treeTableComponent.setData('scrollTriggered' , true)
					treeTableComponent.animation = window.requestAnimationFrame(scrollTable.bind(eve))
				} else if((eve.clientY < (tableDim.top + tableDim.height)) && eve.clientY > (tableDim.top)){
					window.cancelAnimationFrame(treeTableComponent.animation)
					treeTableComponent.setData('stopScroll' , true)
					treeTableComponent.setData('scrollTriggered' , false)
				}
	
				treeTableComponent.setData('exeDrop' , true)
	
			}
		}

	},
	mouseLeaveFunction : function(eve){

		var treeTableComponent = $L('.lyteTableRowActive').closest('lyte-tree-table')[0].component
		var treeTable = treeTableComponent.$node

		var dummyTreeContent = $L('.lyteTreeClonedRow')
		if(dummyTreeContent[0]){
			dummyTreeContent[0].remove()	
		}
		
		if(treeTableComponent.animation){
			window.cancelAnimationFrame(treeTableComponent.animation)
			treeTableComponent.setData('stopScroll' , true)
			treeTableComponent.setData('scrollTriggered' , false)
		}

		if(treeTableComponent.getData('exeDrop') && $L(eve.target).closest('tbody')[0]){

			var currentTr = $L(eve.target).closest('tr')
			var currentDraggedTr = $L(treeTable).find('.lyteTableRowActive').closest('tr')
			var currentDraggedDomIndex = parseInt(currentDraggedTr.attr('dom-index'))
			var currentDropDomIndex = parseInt(currentTr.attr('dom-index'))
			var treeTableLevelData = treeTableComponent.data.treeTableLevelMaps

			var cdLevel = treeTableLevelData[currentDraggedDomIndex].level
			var cdDataIndex = treeTableLevelData[currentDraggedDomIndex].dataIndex
			var cdArrayIndex = treeTableLevelData[currentDraggedDomIndex].arrayIndex
			var cdParentPath = treeTableLevelData[currentDraggedDomIndex].parentPath
			var cdObj = treeTableComponent.getTreeObj(cdDataIndex)

			var cdrLevel = treeTableLevelData[currentDropDomIndex].level
			var cdrDataIndex = treeTableLevelData[currentDropDomIndex].dataIndex
			var cdrArrayIndex = treeTableLevelData[currentDropDomIndex].arrayIndex
			var cdrParentPath = treeTableLevelData[currentDropDomIndex].parentPath
			var cdrObj = treeTableComponent.getTreeObj(cdrDataIndex)
			var cdrState = treeTableLevelData[currentDropDomIndex].stateCollapsed

			if(!treeTableComponent.isSame(currentDraggedTr.attr('data-index') , currentTr.attr('data-index'))){
					
				treeTableComponent.removeDataFromOrigin(cdParentPath , cdArrayIndex)
				treeTableComponent.removeDataFromLinearArray(currentDraggedDomIndex , treeTableComponent.findSibling(cdDataIndex) - currentDraggedDomIndex)
				treeTableComponent.updateMapArray(treeTableComponent.getTreeObj(cdParentPath) , cdParentPath , cdLevel-1 , cdrState)

				if(currentTr.hasClass('lyteTreeTableTopDropzone')){
					var newMaps = treeTableComponent.generateMapArrayForSib(cdObj , cdrParentPath , cdrObj , cdrLevel-1)
					var newObjects = treeTableComponent.generateFlatArray(cdObj)
					var newPushInd = currentTr.attr('dom-index')
					treeTableComponent.insertDatasToLinearArray(newObjects , newPushInd)
					treeTableComponent.insertDatasToMapArray(newMaps , newPushInd)
					treeTableComponent.addDataToOriginAsSib(treeTableComponent.getTreeObj(cdrParentPath) , cdObj , treeTableComponent.getCurrentIndex(cdrDataIndex))
					treeTableComponent.updateMapArray(treeTableComponent.getTreeObj(cdrParentPath) , cdrParentPath , cdrLevel-1)

				}
				
				if(currentTr.hasClass('lyteTreeTableDropzone')){
					if(!cdrState){
						var newMaps = treeTableComponent.generateMapArray(cdObj , cdrDataIndex , cdrObj , cdrLevel)
						var newObjects = treeTableComponent.generateFlatArray(cdObj)
						var newPushInd = treeTableComponent.findSibling(cdrDataIndex)
						treeTableComponent.insertDatasToLinearArray(newObjects , newPushInd)
						treeTableComponent.insertDatasToMapArray(newMaps , newPushInd)
					}
	
					treeTableComponent.addDataToOriginAsChild(cdrObj , cdObj)
				}

				if(currentTr.hasClass('lyteTreeTableBottomDropzone')){
					var newMaps = treeTableComponent.generateMapArrayForSib(cdObj , cdrParentPath , treeTableComponent.getTreeObj(cdrParentPath) , cdrLevel-1 , cdrArrayIndex+1)
					var newObjects = treeTableComponent.generateFlatArray(cdObj)
					var newPushInd = treeTableComponent.findSibling(cdrDataIndex)

					treeTableComponent.insertDatasToLinearArray(newObjects , newPushInd)
					treeTableComponent.insertDatasToMapArray(newMaps , newPushInd)
					treeTableComponent.addDataToOriginAsSib(treeTableComponent.getTreeObj(cdrParentPath) , cdObj , cdrArrayIndex+1 )
					treeTableComponent.updateMapArray(treeTableComponent.getTreeObj(cdrParentPath) , cdrParentPath , cdrLevel-1)
				}

			}
			treeTableComponent.setData('exeDrop' , false)
		}

		$L(treeTable).find('.lyteTableRowActive').removeClass('lyteTableRowActive');
		$L(treeTable).find('.lyteTreeTableDropzone').removeClass('lyteTreeTableDropzone')
		$L(treeTable).find('.lyteTTDropNotAllowed').removeClass('lyteTTDropNotAllowed')
		$L(treeTable).find('.lyteTTDropAllowed').removeClass('lyteTTDropAllowed')
		$L(treeTable).find('.lyteTreeTableTopDropzone').removeClass('lyteTreeTableTopDropzone')
		$L(treeTable).find('.lyteTreeTableBottomDropzone').removeClass('lyteTreeTableBottomDropzone')
		this.removeEventListener('mousemove' , treeTableComponent.mouseMoveFunction)
        this.removeEventListener('mouseup' , treeTableComponent.mouseLeaveFunction)

	}
});

if (!_lyteUiUtils.registeredCustomElements['lyte-tree-table-icon']) {
	_lyteUiUtils.registeredCustomElements['lyte-tree-table-icon'] = true;
	Lyte.createCustomElement("lyte-tree-table-icon", {
	  static: {
		"observedAttributes": {
		  get: function() {
			return [];
		  }
		}
	  },
  
	  "connectedCallback": function() {
		
	  },
  
	  constructor : function(){

		this.addEventListener('click' , function(){

			var currentRow = $L(this).closest('.lyteTreeTableRow')
			var currenttreeTable = $L(this).closest('lyte-tree-table')

			var currentLevel = parseInt(currentRow.attr('data-level'))
			var nextLevel = currentLevel+1
			var currentDataIndex = currentRow.attr('data-index')

			if(currentRow.hasClass('lyteTreeTableOpenedRow')){
				currentRow.addClass('lyteTreeTableClosedRow')
				currentRow.removeClass('lyteTreeTableOpenedRow')
				var nextElem = currenttreeTable[0].component.getNextElem(currentDataIndex)
				$L(currentRow).nextUntil(".lyteTreeTableRow[data-index='"+nextElem+"']").addClass('lyteTreeTableDispNone')
				$L(currentRow).nextUntil(".lyteTreeTableRow[data-index='"+nextElem+"']").addClass('lyteTreeTableStateClosed')
			} else if(currentRow.hasClass('lyteTreeTableClosedRow')){
				currentRow.addClass('lyteTreeTableOpenedRow')
				currentRow.removeClass('lyteTreeTableClosedRow')
				$L(currentRow).nextUntil(".lyteTreeTableRow[data-index='"+nextElem+"']").removeClass('lyteTreeTableDispNone')
				// $L(currentRow).nextUntil(".lyteTreeTableRow[data-level='"+currentLevel+"']").filter( "[data-level='"+nextLevel+"']" ).removeClass('lyteTreeTableDispNone')
			}
		})

	  }
	})  
}

if (!_lyteUiUtils.registeredCustomElements['lyte-tree-table-tr']) {
	_lyteUiUtils.registeredCustomElements['lyte-tree-table-tr'] = true;
	Lyte.createCustomElement("lyte-tree-table-tr", {
	  static: {
		"observedAttributes": {
		  get: function() {
			return [];
		  }
		}
	  },
  
	  "connectedCallback": function() {
		
	  },
  
	  constructor : function(){
		
	  }
	})  
}

if (!_lyteUiUtils.registeredCustomElements['lyte-tree-table-th']) {
_lyteUiUtils.registeredCustomElements['lyte-tree-table-th'] = true;
Lyte.createCustomElement("lyte-tree-table-th", {
	static: {
	"observedAttributes": {
		get: function() {
		return [];
		}
	}
	},

	"connectedCallback": function() {
	
	},

	constructor : function(){
		var currYield = $L(this).closest('lyte-yield')
		var currInd = currYield.find('lyte-tree-table-th').indexOf(this);
		$L(this).attr("head-index" , currInd)
		$L(this).addClass('lyteTreeTableHead'+currInd)
		if($L(this)[0].hasAttribute('fixed')){
			$L(this).addClass('lyteTreeFixedHeader')
		}
	}
})  
}

if (!_lyteUiUtils.registeredCustomElements['lyte-tree-table-td']) {
_lyteUiUtils.registeredCustomElements['lyte-tree-table-td'] = true;
Lyte.createCustomElement("lyte-tree-table-td", {
	static: {
	"observedAttributes": {
		get: function() {
		return [];
		}
	}
	},

	"connectedCallback": function() {
	
	},

	constructor : function(){

		var currYield = $L(this).closest('lyte-yield')
		var currInd = currYield.find('lyte-tree-table-td').indexOf(this);
		$L(this).addClass('lyteTreeTableCol'+currInd)
		$L(this).attr("col-index" , currInd)
	}
})
}